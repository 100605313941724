import { FC, useEffect, useState, lazy } from "react";
import AggerTheme from "./utils/AggerTheme";
import { Box, ThemeProvider, GridProps, Button } from "@mui/material";
import { Container } from "@mui/system";
import Cookies from "js-cookie";

import { useWindowSize } from "./utils/Utility";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Logo from "./images/logoRodape.png";
import Selo from "./images/Selo.jpg";

import { Link, Stack } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FormModal from "../src/components/FormModal/FormModalSection";

import CookieConsent from "react-cookie-consent";
import { animateScroll } from "react-scroll";
import { SelectedPlanoInformation } from "./components/Plano/PlanoType";
import { useFormModalContentContext } from "./components/FormModal/FormModalContext";
const ContatoExterno = lazy(() => import("./components/ContatoExterno/ContatoExterno"));
const Navbar = lazy(() => import("./components/Navbar/NavbarSection"));
const Sidebar = lazy(() => import("./components/Sidebar/Sidebar"));

const ContatoSection = lazy(() => import("./components/Contato/ContatoSection"));
const SobreNosSection = lazy(() => import("./components/SobreNos/SobreNosSection"));
const DepoimentosSection = lazy(() => import("./components/Depoimento/DepoimentosSection"));
const NossasSolucoesSection = lazy(
    () => import("./components/NossasSolucoes/NossasSolucoesSection")
);
const FuncionalidadesSection = lazy(
    () => import("./components/Funcionalidade/FuncionalidadesSection")
);
const PlanosSection = lazy(() => import("./components/Plano/PlanosSection"));
const LoginForm = lazy(() => import("./components/Login/LoginForm"));
const RISection = lazy(() => import("./components/RI/RISection"));
const AppContentProvider = lazy(() => import("./components/Contexts/AppContentContext"));
const cookieName = "cmRzdGF0aW9uLXBvcHVwLTM4NjY1NTQtdmlld2Vk";
const cookieValue = "viewed";

interface Props {
    contrate: boolean;
}

const Main: FC<Props> = (props) => {
    const [windowsWidth] = useWindowSize();
    const [mainWidthAvaliable, setMainWidthAvaliable] = useState<number>();
    const [mainMarginLeft, setMainMarginLeft] = useState(0);
    const { showFormModal } = useFormModalContentContext();

    const [selectedPlanoIndexInformation, settSelectedPlanoIndexInformation] =
        useState<SelectedPlanoInformation>({
            planIndex: 0,
            licenceIndex: 0,
        });

    const handleSidebarOpen = (sidebarWidth: number) => {
        setMainWidthAvaliable(windowsWidth - sidebarWidth);
        setMainMarginLeft(sidebarWidth);
    };

    const handleSidebarClose = () => {
        setMainWidthAvaliable(windowsWidth);
        setMainMarginLeft(0);
    };

    const gridContainerDefaultProps: GridProps = {
        container: true,
        rowGap: 6,
        justifyContent: "center",
        maxWidth: "desktop",
        m: "auto",
    };

    useEffect(() => {
        if (Cookies.get(cookieName) === cookieValue) {
            Cookies.set(cookieName, "");
        }
        if (props.contrate) {
            let element = document.getElementById("planos_section");

            if (element) {
                let elemRect = element.getBoundingClientRect();
                let offset = elemRect.top + elemRect.top * 0.07;

                animateScroll.scrollTo(offset);
            }
        }
    }, [props.contrate]);

    const handleSelectPlan = () => {
        settSelectedPlanoIndexInformation({
            planIndex: 2,
            licenceIndex: 0,
        });
        showFormModal();
    };

    return (
        <>
            <FormModal selectedPlanoIndexInformation={selectedPlanoIndexInformation} />
            <Sidebar
                onOpenCallback={handleSidebarOpen}
                onCloseCallback={handleSidebarClose}
            ></Sidebar>
            <Box
                component="main"
                sx={{
                    width: mainWidthAvaliable,
                    margin: 0,
                    marginLeft: `${mainMarginLeft}px`,
                }}
            >
                <Container disableGutters maxWidth={false} sx={{ width: "100%" }}>
                    <Navbar></Navbar>

                    {/** Contato section */}
                    <ContatoSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Sobre Nós section  */}
                    <SobreNosSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Depoimentos section */}
                    <DepoimentosSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Nossas Soluções section */}
                    <NossasSolucoesSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Funcionalidades section */}
                    <FuncionalidadesSection gridDefaultProps={gridContainerDefaultProps} />

                    {/** Planos section */}
                    <PlanosSection gridDefaultProps={gridContainerDefaultProps} />
                    <Container maxWidth="desktop">
                        <Box
                            sx={{
                                display: { mobile: "none", laptop: "flex" },
                                mr: 1,
                                my: 2,
                                justifyContent: "space-between",
                            }}
                        >
                            <img src={Logo} width={210} height={"auto"} alt="Agger logo" />

                            <Box sx={{ display: "flex" }}>
                                <img src={Selo} width={100} alt="Selo Agger" />
                                <Stack
                                    direction={"column"}
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Stack direction="row" alignItems="center" spacing={0}>
                                        <p
                                            style={{
                                                color: "#005b87",
                                                fontWeight: "bold",
                                                margin: "0 30px 0 30px",
                                                fontFamily:
                                                    "Asap Regular,Asap Medium,Asap SemiBold,Asap Bold,K2D Thin,K2D ExtraLight,K2D Light,K2D Regular,K2D SemiBold,K2D Bold,K2D ExtraBold",
                                                fontSize: "20px",
                                            }}
                                        >
                                            SIGA-NOS
                                        </p>

                                        <Link
                                            href="https://www.facebook.com/aggersistemas"
                                            target="_blank"
                                            rel="noopener"
                                            sx={{ cursor: "default" }}
                                        >
                                            <FacebookIcon
                                                sx={{
                                                    cursor: "pointer",
                                                    width: "30px",
                                                    color: "primary",
                                                    transition: "0.5s transform ease",
                                                    "&:hover": { transform: "scale(1.1)" },
                                                }}
                                            />
                                        </Link>
                                        <Link
                                            href="https://www.linkedin.com/company/aggersistemas/?original_referer=https%3A%2F%2Fagger.com.br%2F"
                                            target="_blank"
                                            rel="noopener"
                                            sx={{ cursor: "default" }}
                                        >
                                            <LinkedInIcon
                                                sx={{
                                                    cursor: "pointer",
                                                    width: "30px",
                                                    color: "primary",
                                                    transition: "0.5s transform ease",
                                                    "&:hover": { transform: "scale(1.1)" },
                                                }}
                                            />
                                        </Link>
                                        <Link
                                            href="https://www.instagram.com/aggersistemas/"
                                            target="_blank"
                                            rel="noopener"
                                            sx={{ cursor: "default" }}
                                        >
                                            <InstagramIcon
                                                sx={{
                                                    cursor: "pointer",
                                                    width: "30px",
                                                    color: "primary",
                                                    transition: "0.5s transform ease",
                                                    "&:hover": { transform: "scale(1.1)" },
                                                }}
                                            />
                                        </Link>
                                        {/* <Link
                                            href="/ri"
                                            target="_blank"
                                            rel="noopener"
                                            sx={{
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                                fontSize: "1.3rem",
                                                textDecoration: "none",
                                            }}
                                        >
                                            RI
                                        </Link> */}
                                    </Stack>
                                    <Button
                                        style={{
                                            fontSize: "1.2rem",
                                            cursor: "pointer",
                                            color: "#005b87",
                                            textTransform: "initial",
                                            fontFamily:
                                                "Asap Regular,Asap Medium,Asap SemiBold,Asap Bold,K2D Thin,K2D ExtraLight,K2D Light,K2D Regular,K2D SemiBold,K2D Bold,K2D ExtraBold",
                                        }}
                                        className="text-center"
                                        onClick={handleSelectPlan}
                                    >
                                        Quero um contato
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </Container>
                </Container>
                <Box
                    sx={{
                        display: { mobile: "none", laptop: "block" },
                        background: "lightgray",
                        py: 0.1,
                    }}
                    alignItems="center"
                    textAlign={"center"}
                >
                    <p
                        style={{
                            fontSize: "20px",
                            color: "#005b87",
                            fontFamily:
                                "Asap Regular,Asap Medium,Asap SemiBold,Asap Bold,K2D Thin,K2D ExtraLight,K2D Light,K2D Regular,K2D SemiBold,K2D Bold,K2D ExtraBold",
                        }}
                        className="text-center"
                    >
                        ©2023 AGGER. ALL RIGHTS RESERVED | CNPJ 00.585.578/0001-57
                    </p>
                </Box>

                <Box
                    sx={{
                        display: { mobile: "block", laptop: "none" },
                        background: "lightgray",
                        py: 0.1,
                    }}
                    alignItems="center"
                    textAlign={"center"}
                >
                    <Button
                        style={{
                            fontSize: "1.2rem",
                            cursor: "pointer",
                            color: "#005b87",
                            textTransform: "initial",
                            fontFamily:
                                "Asap Regular,Asap Medium,Asap SemiBold,Asap Bold,K2D Thin,K2D ExtraLight,K2D Light,K2D Regular,K2D SemiBold,K2D Bold,K2D ExtraBold",
                        }}
                        className="text-center"
                        onClick={handleSelectPlan}
                    >
                        Quero um contato
                    </Button>
                    <p
                        style={{
                            fontSize: "12px",
                            color: "#005b87",
                            fontFamily:
                                "Asap Regular,Asap Medium,Asap SemiBold,Asap Bold,K2D Thin,K2D ExtraLight,K2D Light,K2D Regular,K2D SemiBold,K2D Bold,K2D ExtraBold",
                        }}
                        className="text-center"
                    >
                        ©2023 AGGER. ALL RIGHTS RESERVED | CNPJ 00.585.578/0001-57
                    </p>
                </Box>
            </Box>

            <CookieConsent
                location="bottom"
                buttonText="CIENTE"
                declineButtonText="REJEITAR"
                cookieName="cookieNotification"
                style={{
                    fontFamily: "Asap Bold",
                    background: "#005B87",
                    color: "white",
                    fontSize: "15px",
                }}
                buttonStyle={{
                    fontFamily: "Asap Bold",
                    color: "#003050",
                    fontSize: "15px",
                    padding: "15px 50px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                }}
                declineButtonStyle={{
                    fontFamily: "Asap Bold",
                    color: "#003050",
                    fontSize: "15px",
                    padding: "15px 50px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    background: "#FFD526",
                }}
            >
                <p style={{ fontSize: "16px" }}>
                    Este site utiliza apenas cookies necessários, ou seja, aqueles sem os quais o
                    site não realizará funções básicas ou operará corretamente. Para mais
                    informações sobre o uso de dados pessoais, consulte nossa{" "}
                    <a
                        href="https://aggerinstala.blob.core.windows.net/docs/Declaracao_Privacidade.pdf"
                        target="_blank"
                        style={{ color: "white" }}
                    >
                        Declaração de Privacidade.
                    </a>
                </p>
            </CookieConsent>
        </>
    );
};

function App() {
    return (
        <ThemeProvider theme={AggerTheme}>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                language="pt-BR"
            >
                <AppContentProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Main contrate={false} />} />
                            <Route path="/contrate" element={<Main contrate={true} />}></Route>
                            <Route path="/login" element={<LoginForm />}></Route>
                            <Route path="/ri" element={<RISection />}></Route>
                            <Route path="/contato" element={<ContatoExterno />}></Route>
                            <Route
                                path="/atendimento"
                                Component={() => {
                                    window.location.href =
                                        "https://central.agger.com.br/Atendimento";
                                    return null;
                                }}
                            />
                        </Routes>
                    </BrowserRouter>
                </AppContentProvider>
            </GoogleReCaptchaProvider>
        </ThemeProvider>
    );
}

export default App;
